@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLXSPJVVvS7pivFi9a_v_830O_kPjaewOT7xyLjus2-9PB6GDs-01xiojPuFmmAe3kg');

.c23 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #19AAF8;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 153pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c26 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #19AAF8;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 153pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c29 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #f3f3f3;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c87 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 76.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c27 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 238.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c80 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 72pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c61 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 108pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.print-source {
  display: none;
  /* background-color: red; */
}

@media print {
  .print-source {
    display: block;
    /* background-color: green; */
  }
}

.c75 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 215.2pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c85 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 476.2pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c64 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 72pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c65 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 327pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c74 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 76.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c3 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 153pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c78 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c38 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 544.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c68 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 226.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c33 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 215.2pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c56 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 108pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c83 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 76.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c63 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 309.8pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c51 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 72pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c44 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c7 {
  margin-left: 252pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt
}

.c37 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 12pt
}

.c14 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.c10 {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c6 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt
}

.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c22 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c32 {
  color: #de478e;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt
}

.c15 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal
}

.c0 {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c19 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  width: 700px;
  text-align: left
}

.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: right
}

.c52 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: center
}

.c76 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: right
}

.c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c39 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 12pt
}

.c35 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal
}

.c59 {
  -webkit-text-decoration-skip: none;
  color: #19AAF8;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 9pt
}

.c54 {
  margin-left: 238.5pt;
  border-spacing: 0;
  border-collapse: collapse;
  width: 375px;
  margin-right: auto
}

.c17 {
  margin-left: -5.8pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
  width: 700px;
}

.c62 {
  font-size: 10pt;
  font-family: "Calibri";
  font-style: italic;
  font-weight: 400
}

.c47 {
  color: #19AAF8;
  font-weight: 700;
  font-size: 16pt;
  font-family: "Trebuchet MS"
}

.c11 {
  font-size: 30pt;
  font-family: "Trebuchet MS";
  color: #ffffff;
  font-weight: 400
}

.c16 {
  font-size: 10pt;
  font-family: "Trebuchet MS";
  color: #000000;
  font-weight: 700
}

.c66 {
  margin-left: -6.5pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
  width: 703px;
}

.c12 {
  font-size: 11pt;
  font-family: "Trebuchet MS";
  color: #ffffff;
  font-weight: 700
}

.c53 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-style: italic
}

.c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left
}

.c50 {
  margin-left: -8.2pt;
  border-spacing: 0;
  border-collapse: collapse;
  width: 703px;
  margin-right: auto
}

.c71 {
  font-weight: 400;
  font-size: 9pt;
  font-family: "Times New Roman"
}

.c21 {
  font-size: 14pt;
  font-family: "Trebuchet MS";
  font-weight: 400
}

.c49 {
  font-weight: 400;
  font-size: 12pt;
  font-family: "Calibri"
}

.c20 {
  font-size: 10pt;
  font-family: "Trebuchet MS";
  font-weight: 400;
  margin-left: -10px;
  margin-right: 10px;
}

.c58 {
  font-size: 14pt;
  font-family: "Trebuchet MS";
  font-weight: 700;
  margin-left: -10px;
}

.c67 {
  font-weight: 400;
  font-size: 12pt;
  font-family: "Gill Sans"
}

.c72 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none
}

.c25 {
  border-bottom-color: #dfdbd5;
  border-bottom-width: 0.8pt;
  border-bottom-style: solid;
  width: 700px;
}

.c60 {
  background-color: #ffffff;
  max-width: 527.8pt;
  padding: 36pt 36pt 36pt 31.5pt
}

.c34 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal
}

.c55 {
  font-size: 13pt;
  font-family: "Trebuchet MS";
  font-weight: 700
}

.c86 {
  font-size: 10pt;
  font-style: italic
}

.c9 {
  font-weight: 700;
  font-family: "Calibri"
}

.c84 {
  font-weight: 400;
  font-family: "Calibri"
}

.c43 {
  color: inherit;
  text-decoration: inherit
}

.c45 {
  color: #818181;
  font-size: 9pt
}

.c79 {
  margin-left: 216pt;
  text-indent: 36pt
}

.c28 {
  font-weight: 400;
  font-family: "Trebuchet MS"
}

.c77 {
  height: 39.1pt
}

.c57 {
  height: 19.5pt
}

.c69 {
  color: #ffffff
}

.c41 {
  font-size: 11pt
}

.c36 {
  height: 31pt
}

.c88 {
  margin-left: 252pt
}

.c46 {
  height: 12pt
}

.c42 {
  height: 12.6pt
}

.c8 {
  margin-right: 8.2pt
}

.c73 {
  background-color: #ffffff
}

.c40 {
  height: 69pt
}

.c30 {
  color: #000000
}

.c82 {
  height: 1pt
}

.c70 {
  font-size: 16pt
}

.c31 {
  margin-right: 8.5pt
}

.c81 {
  font-size: 15pt
}

.c48 {
  background-color: #19AAF8
}

.c4 {
  height: 0pt
}

.c89 {
  background-color: #f3f3f3
}

.c90 {
  height: 30pt
}

p[name="goHTML"] {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  /* font-family: "Gill Sans" */
}

/* Scrollbar Styling */
#mySider::-webkit-scrollbar  {
  width: 5px;
}

#mySider::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 5px;
}

#mySider::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 5px;
  background: #ffffff; 
}
.ant-select-selector {
  border: 1px solid #19AAF8 !important; }

  #tbl th {
    text-align: left;
  }


  /* Scrollbar Styling */
.dynamicSMS::-webkit-scrollbar  {
  height: 5px;
  width: 5px;
}

.dynamicSMS::-webkit-scrollbar-track {
  background-color: #d6dddb;
  -webkit-border-radius: 10px;
  border-radius: 5px;
}

.dynamicSMS::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #19AAF8; 
}
/*--------------------------------------------------------------
>> TABLE OF CONTENTS:
----------------------------------------------------------------
1. Normalize
2. Typography
3. Invoice General Style
--------------------------------------------------------------*/
/*--------------------------------------------------------------
2. Normalize
----------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");


/*--------------------------------------------------------------
2. Typography
----------------------------------------------------------------*/

#billinvoice {
  color: #777777;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  overflow-x: hidden;
 
}


.billinvoice img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}



.billinvoice table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

.billinvoice th {
  text-align: left;
}

.billinvoice td {
  border-top: 1px solid #eaeaea;
}

.billinvoice th {
    padding: 10px 15px;
    line-height: 1.55em;
  }
.billinvoice td {
    padding: 10px 15px;
    line-height: 1.55em;
  }

/*--------------------------------------------------------------
3. Invoice General Style
----------------------------------------------------------------*/
.cs-f10 {
  font-size: 10px;
}

.cs-f11 {
  font-size: 11px;
}

.cs-f12 {
  font-size: 12px;
}

.cs-f13 {
  font-size: 13px;
}

.cs-f14 {
  font-size: 14px;
}

.cs-f15 {
  font-size: 15px;
}

.cs-f16 {
  font-size: 16px;
}

.cs-f17 {
  font-size: 17px;
}

.cs-f18 {
  font-size: 18px;
}

.cs-f19 {
  font-size: 19px;
}

.cs-f20 {
  font-size: 20px;
}

.cs-f21 {
  font-size: 21px;
}

.cs-f22 {
  font-size: 22px;
}

.cs-f23 {
  font-size: 23px;
}

.cs-f24 {
  font-size: 24px;
}

.cs-f25 {
  font-size: 25px;
}

.cs-f26 {
  font-size: 26px;
}

.cs-f27 {
  font-size: 27px;
}

.cs-f28 {
  font-size: 28px;
}

.cs-f29 {
  font-size: 29px;
}

.cs-light {
  font-weight: 300;
}

.cs-normal {
  font-weight: 400;
}

.cs-medium {
  font-weight: 500;
}

.cs-semi_bold {
  font-weight: 600;
}

.cs-bold {
  font-weight: 700;
}

.cs-m0 {
  margin: 0px;
}

.cs-mb0 {
  margin-bottom: 0px;
}

.cs-mb1 {
  margin-bottom: 1px;
}

.cs-mb2 {
  margin-bottom: 2px;
}

.cs-mb3 {
  margin-bottom: 3px;
}

.cs-mb4 {
  margin-bottom: 4px;
}

.cs-mb5 {
  margin-bottom: 5px;
}

.cs-mb6 {
  margin-bottom: 6px;
}

.cs-mb7 {
  margin-bottom: 7px;
}

.cs-mb8 {
  margin-bottom: 8px;
}

.cs-mb9 {
  margin-bottom: 9px;
}

.cs-mb10 {
  margin-bottom: 10px;
}

.cs-mb11 {
  margin-bottom: 11px;
}

.cs-mb12 {
  margin-bottom: 12px;
}

.cs-mb13 {
  margin-bottom: 13px;
}

.cs-mb14 {
  margin-bottom: 14px;
}

.cs-mb15 {
  margin-bottom: 15px;
}

.cs-mb16 {
  margin-bottom: 16px;
}

.cs-mb17 {
  margin-bottom: 17px;
}

.cs-mb18 {
  margin-bottom: 18px;
}

.cs-mb19 {
  margin-bottom: 19px;
}

.cs-mb20 {
  margin-bottom: 20px;
}

.cs-mb21 {
  margin-bottom: 21px;
}

.cs-mb22 {
  margin-bottom: 22px;
}

.cs-mb23 {
  margin-bottom: 23px;
}

.cs-mb24 {
  margin-bottom: 24px;
}

.cs-mb25 {
  margin-bottom: 25px;
}

.cs-mb26 {
  margin-bottom: 26px;
}

.cs-mb27 {
  margin-bottom: 27px;
}

.cs-mb28 {
  margin-bottom: 28px;
}

.cs-mb29 {
  margin-bottom: 29px;
}

.cs-mb30 {
  margin-bottom: 30px;
}

.cs-pt25 {
  padding-top: 25px;
}

.cs-width_1 {
  width: 8.33333333%;
}

.cs-width_2 {
  width: 16.66666667%;
}

.cs-width_3 {
  width: 25%;
}

.cs-width_4 {
  width: 33.33333333%;
}

.cs-width_5 {
  width: 41.66666667%;
}

.cs-width_6 {
  width: 50%;
}

.cs-width_7 {
  width: 58.33333333%;
}

.cs-width_8 {
  width: 66.66666667%;
}

.cs-width_9 {
  width: 75%;
}

.cs-width_10 {
  width: 83.33333333%;
}

.cs-width_11 {
  width: 91.66666667%;
}

.cs-width_12 {
  width: 100%;
}

.cs-accent_color,
.cs-accent_color_hover:hover {
  color: #19AAF8;
}

.cs-accent_bg,
.cs-accent_bg_hover:hover {
  background-color: #19AAF8;
}

.cs-primary_color {
  color: #111111;
}

.cs-secondary_color {
  color: #777777;
}

.cs-ternary_color {
  color: #353535;
}

.cs-ternary_color {
  border-color: #eaeaea;
}

.cs-focus_bg {
  background: #f6f6f6;
}

.cs-accent_10_bg {
  background-color: rgba(42, 209, 157, 0.1);
}

.cs-container {
  max-width: 880px;
  padding: 30px 15px;
  margin-left: auto;
  margin-right: auto;
}

.cs-text_center {
  text-align: center;
}

.cs-text_right {
  text-align: right;
}

.cs-border_bottom_0 {
  border-bottom: 0;
}

.cs-border_top_0 {
  border-top: 0;
}

.cs-border_bottom {
  border-bottom: 1px solid #eaeaea;
}

.cs-border_top {
  border-top: 1px solid #eaeaea;
}

.cs-border_left {
  border-left: 1px solid #eaeaea;
}

.cs-border_right {
  border-right: 1px solid #eaeaea;
}

.cs-table_baseline {
  vertical-align: baseline;
}

.cs-round_border {
  border: 1px solid #eaeaea;
  overflow: hidden;
  border-radius: 6px;
}

.cs-border_none {
  border: none;
}

.cs-border_left_none {
  border-left-width: 0;
}

.cs-border_right_none {
  border-right-width: 0;
}

.cs-invoice.cs-style1 {
  background: #fff;
  border-radius: 10px;
  padding: 50px;
}

.cs-invoice.cs-style1 .cs-invoice_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cs-invoice.cs-style1 .cs-invoice_head.cs-type1 {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
}

.cs-invoice.cs-style1 .cs-invoice_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-invoice.cs-style1 .cs-invoice_footer table {
  margin-top: -1px;
}

.cs-invoice.cs-style1 .cs-left_footer {
  width: 55%;
  padding: 10px 15px;
}

.cs-invoice.cs-style1 .cs-right_footer {
  width: 46%;
}

.cs-invoice.cs-style1 .cs-note {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 40px;
}

.cs-invoice.cs-style1 .cs-note_left {
  margin-right: 10px;
  margin-top: 6px;
  margin-left: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-invoice.cs-style1 .cs-note_left svg {
  width: 32px;
}

.cs-invoice.cs-style1 .cs-invoice_left {
  max-width: 55%;
}

.cs-invoice_btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.cs-invoice_btns .cs-invoice_btn:first-child {
  border-radius: 5px 0 0 5px;
}

.cs-invoice_btns .cs-invoice_btn:last-child {
  border-radius: 0 5px 5px 0;
}

.cs-invoice_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  font-weight: 600;
  padding: 8px 20px;
  cursor: pointer;
}

.cs-invoice_btn svg {
  width: 24px;
  margin-right: 5px;
}

.cs-invoice_btn.cs-color1 {
  color: #111111;
  background: rgba(42, 209, 157, 0.15);
}

.cs-invoice_btn.cs-color1:hover {
  background-color: rgba(42, 209, 157, 0.3);
}

.cs-invoice_btn.cs-color2 {
  color: #fff;
  background: #19AAF8;
}

.cs-invoice_btn.cs-color2:hover {
  background-color:  #19AAF8;
}

.cs-table_responsive {
  overflow-x: auto;
}

.cs-table_responsive > table {
  min-width: 600px;
}

.cs-50_col > * {
  width: 50%;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}

.cs-bar_list {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cs-bar_list::before {
  content: '';
  height: 75%;
  width: 2px;
  position: absolute;
  left: 4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #eaeaea;
}

.cs-bar_list li {
  position: relative;
  padding-left: 25px;
}

.cs-bar_list li:before {
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #eaeaea;
  position: absolute;
  left: 0;
  top: 6px;
}

.cs-bar_list li:not(:last-child) {
  margin-bottom: 10px;
}

.cs-table.cs-style1.cs-type1 {
  padding: 10px 30px;
}

.cs-table.cs-style1.cs-type1 tr:first-child td {
  border-top: none;
}

.cs-table.cs-style1.cs-type1 tr td:first-child {
  padding-left: 0;
}

.cs-table.cs-style1.cs-type1 tr td:last-child {
  padding-right: 0;
}

.cs-table.cs-style1.cs-type2 > * {
  padding: 0 10px;
}

.cs-table.cs-style1.cs-type2 .cs-table_title {
  padding: 20px 0 0 15px;
  margin-bottom: -5px;
}

.cs-table.cs-style2 td {
  border: none;
}

.cs-table.cs-style2 td,
.cs-table.cs-style2 th {
  padding: 12px 15px;
  line-height: 1.55em;
}

.cs-table.cs-style2 tr:not(:first-child) {
  border-top: 1px dashed #eaeaea;
}

.cs-list.cs-style1 {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cs-list.cs-style1 li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-list.cs-style1 li:not(:last-child) {
  border-bottom: 1px dashed #eaeaea;
}

.cs-list.cs-style1 li > * {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  width: 50%;
  padding: 7px 0px;
}

.cs-list.cs-style2 {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 12px 0;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.cs-list.cs-style2 li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-list.cs-style2 li > * {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 5px 25px;
}

.cs-heading.cs-style1 {
  line-height: 1.5em;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}

.cs-no_border {
  border: none !important;
}

.cs-grid_row {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  list-style: none;
  padding: 0;
}

.cs-col_2 {
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.cs-col_3 {
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.cs-col_4 {
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
}

.cs-border_less td {
  border-color: transparent;
}

.cs-special_item {
  position: relative;
}

.cs-special_item:after {
  content: '';
  height: 52px;
  width: 1px;
  background-color: #eaeaea;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
}

.cs-table.cs-style1 .cs-table.cs-style1 tr:not(:first-child) td {
  border-color: #eaeaea;
}

.cs-table.cs-style1 .cs-table.cs-style2 td {
  padding: 12px 0px;
}

.cs-ticket_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-ticket_left {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.cs-ticket_right {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  width: 215px;
}

.cs-box.cs-style1 {
  border: 2px solid #eaeaea;
  border-radius: 5px;
  padding: 20px 10px;
  min-width: 150px;
}

.cs-box.cs-style1.cs-type1 {
  padding: 12px 10px 10px;
}

.cs-max_w_150 {
  max-width: 150px;
}

.cs-left_auto {
  margin-left: auto;
}

.cs-title_1 {
  display: inline-block;
  border-bottom: 1px solid #eaeaea;
  min-width: 60%;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.cs-box2_wrap {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  list-style: none;
  padding: 0;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.cs-box.cs-style2 {
  border: 1px solid #eaeaea;
  padding: 25px 30px;
  border-radius: 5px;
}

.cs-box.cs-style2 .cs-table.cs-style2 td {
  padding: 12px 0;
}

#mainEmpty{
  display: table;
  width: 100%;
  height: 75vh;
  text-align: center;
  background-color:'#F0F2F5';
}
.fofempty{
  display: table-cell;
  vertical-align: middle;
}

/* #mobileIns span:last-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 30px;
} */
